<template>
  <div class="bg-gray-100 min-h-screen font-sans">
    <div class="container mx-auto py-8 px-4">
      <h1 class="text-3xl font-bold text-blue-600 mb-6 text-center">智言AI</h1>
      <div class="flex flex-col md:flex-row gap-8">
        <div class="bg-white p-6 rounded-lg shadow-md w-full md:w-1/4">
          <button @click="gohome" class="block w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mb-4">首页</button>
          <button @click="fetchUserInfo" class="block w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mb-4">我的资料</button>
          <button @click="passwordReset" class="block w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mb-4">修改密码</button>
          <!--          <button @click="pay" v-if="shouldShowPayButton" class="block w-full bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded mb-4">会员充值</button>-->
          <button @click="logout" class="block w-full bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">退出系统</button>
        </div>
        <div class="bg-white p-6 rounded-lg shadow-md w-full md:w-3/4" v-if="!userInfo && !error">
          <div class="animate-pulse flex space-x-4">
            <div class="flex-1 space-y-6">
              <div class="h-2 bg-gray-300 rounded"></div>
              <div class="h-2 bg-gray-300 rounded w-1/2"></div>
              <div class="h-2 bg-gray-300 rounded w-1/4"></div>
            </div>
          </div>
        </div>
        <div class="bg-white p-6 rounded-lg shadow-md w-full md:w-3/4" v-if="error">
          <div class="text-red-500 font-bold">{{ error }}</div>
        </div>
        <div class="bg-white p-6 rounded-lg shadow-md w-full md:w-3/4" v-if="userInfo">
          <h2 class="text-xl font-bold mb-4">我的信息</h2>
          <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <span class="font-bold">登录名:</span> {{ userInfo.loginName }}
            </div>
            <div>
              <span class="font-bold">密码:</span> ********
            </div>
            <div>
              <span class="font-bold">标签:</span> {{ userInfo.tag }}
            </div>
            <div>
              <span class="font-bold">是否激活:</span> {{ userInfo.isActive ? '是' : '否' }}
            </div>
            <div>
              <span class="font-bold">智币:</span> {{ userInfo.score }}
            </div>
            <div>
              <span class="font-bold">过期时间:</span> {{ formatTimestamp(userInfo.expirationTime) }}
            </div>
          </div>
          <div class="mt-8">
            <span class="font-bold">我的邀请:</span> {{ userInfo.invitationCode }}
            <button @click="copyInvitationLink" class="ml-4 px-4 py-2 rounded-md bg-blue-500 text-white font-semibold hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75">
              {{ copied ? '已复制' : '复制' }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      userInfo: null,
      error: null,
      copied: false,
    };
  },
  computed: {
    shouldShowPayButton() {
      const invitationCode = localStorage.getItem('invitationCode');
      return !invitationCode;
    },
  },
  created() {
    this.fetchUserInfo();
  },
  methods: {
    getUid() {
      return localStorage.getItem('uid');
    },
    fetchUserInfo() {
      const id = this.getUid();
      if (!id) {
        console.info("用户没有登录 ... ... ...");
        this.$router.push('/');
        return;
      }
      axios.get(`https://chat.chatgptten.com/u/global_users/user/uid/${id}`)
          .then(response => {
            if (response.data.success) {
              this.userInfo = response.data.data;
            } else {
              this.error = response.data.msg;
            }
          })
          .catch(error => {
            this.error = '请求失败';
            console.error('获取用户信息失败:', error);
          });
    },
    logout() {
      this.deleteAllCookies();
      localStorage.removeItem('token');
      localStorage.removeItem('uid');
      localStorage.removeItem('expiration');
      localStorage.removeItem('tag');
      console.log('成功退出1');
      window.location.href = 'https://chat.chatgptten.com/sso/auth?mode=simple&redirect=https://chat.chatgptten.com/';
      console.log('成功退出2');
    },
    deleteAllCookies() {
      const deleteCookie = (name, domain) => {
        document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; domain=${domain}`;
      };

      document.cookie.split(';').forEach(cookie => {
        const [name] = cookie.trim().split('=');
        deleteCookie(name, 'chatgptten.com');
        deleteCookie(name, 'aigc.chatgptten.com');
        deleteCookie(name, 'chat.chatgptten.com');
        deleteCookie(name, 'gw.chatgptten.com');
      });
    },
    gohome() {
      this.$router.push('/');
    },
    passwordReset() {
      this.$router.push('/passwordReset');
    },
    pay() {
      window.location.href = 'https://chat.chatgptten.com/dist/';
    },
    formatTimestamp(timestamp) {
      return new Date(timestamp).toLocaleDateString('zh-CN', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      });
    },
    copyInvitationLink() {
      if (!this.userInfo || !this.userInfo.invitationCode) {
        alert('邀请码不存在，无法复制！');
        return;
      }
      const invitationLink = `https://chat.chatgptten.com/register?iCode=${this.userInfo.invitationCode}`;
      navigator.clipboard.writeText(invitationLink)
          .then(() => {
            this.copied = true;
          })
          .catch(err => {
            console.error('复制失败:', err);
          });
    },
  },
};
</script>