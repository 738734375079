import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '@/components/HomePage';
import NXHomePage from '@/components/HomePageOldPage.vue';
import LoginPage from '@/components/LoginPage';
import RegisterPage from "@/components/RegisterPage.vue";
import QcodePage from "@/components/QcodePage.vue";
import SettingPage from "@/components/SettingPage.vue";
import PasswordResetPage from "@/components/PasswordResetPage.vue";
import CodeLoginPage from "@/components/CodeLoginPage.vue";
import InterfaceIntegrationPlatformPage from "@/components/InterfaceIntegrationPlatformPage.vue";
import PrivacyPolicyPage from "@/components/PrivacyPolicyPage.vue";
import WebsiteIntroductionPage from "@/components/WebsiteIntroductionPage.vue";
import ImagineCtreatorPage from "@/components/ImagineCtreatorPage.vue";
import ImageGalleryPage from "@/components/ImageGalleryPage.vue";
import VideoGeneratorPage from "@/components/VideoGeneratorPage.vue";
import AboutPage from "@/components/AboutPage.vue";
import AdminPage from "@/components/AdminPage.vue";
import AutoAccessPage from "@/components/AutoAccessPage.vue";
import PixverseVideoGeneratorPage from "@/components/PixverseVideoGeneratorPage.vue";
import PikaVideoGeneratorPage from "@/components/PikaVideoGeneratorPage.vue";
const routes = [

  {
    path: '/',
    name: 'Home',
    component: HomePage,
  },
  {
    path: '/naixue',
    name: 'NXHome',
    component: NXHomePage,
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginPage
  },
  {
    path: '/codeLogin',
    name: 'CodeLogin',
    component: CodeLoginPage
  },
  {
    path: "/register",
    name: "Register",
    component: RegisterPage
  },
  {
    path: "/qcode",
    name: "Qcode",
    component: QcodePage
  },
  {
    path: "/setting",
    name: "Setting",
    component: SettingPage
  },
  {
    path: "/passwordReset",
    name: "passwordReset",
    component: PasswordResetPage
  },
  {
    path: "/iip",
    name: "InterfaceIntegrationPlatform",
    component: InterfaceIntegrationPlatformPage
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    component: PrivacyPolicyPage
  },
  {
    path: "/our-company",
    name: "WebsiteIntroduction",
    component: WebsiteIntroductionPage
  },
  {
    path: "/imagine-creator",
    name: "ImagineCtreator",
    component: ImagineCtreatorPage
  },
  {
    path: "/image-gallery",
    name: "ImageGallery",
    component: ImageGalleryPage
  },
  {
    path: "/video-generator2",
    name: "VideoGenerator",
    component: VideoGeneratorPage
  },
  {
    path: "/about",
    name: "About",
    component: AboutPage
  },

  {
    path: "/admin",
    name: "Admin",
    component: AdminPage
  },
  {
    path: "/auto-access",
    name: "AutoAccess",
    component: AutoAccessPage
  },
  {
    path: "/pixverse",
    name: "Pixverse",
    component: PixverseVideoGeneratorPage
  },
  {
    path: "/pika",
    name: "Pika",
    component: PikaVideoGeneratorPage
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;